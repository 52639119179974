import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import http from './http'
import auth from './plugins/auth.js'
import router from './router'
import './index.css'
import Toaster from '@meforma/vue-toaster'
import PusherPlugin from './plugins/pusher'

const app = createApp(App)

app.use(PusherPlugin, { apiKey: '2e95b0e04d3f392cfd0b', cluster: 'eu' })
app.use(Toaster)
app.use(store)
app.use(http)
app.use(router)
app.use(auth)
app.mount('#app')
