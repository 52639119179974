<template>
  <div class="flex flex-col space-y-5">
    <div class="min-w-0 flex-1">
      <h2
        class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
      >
        Upcoming Key Performance Indicator Review
      </h2>
    </div>
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-base font-semibold leading-6 text-gray-900">Self scoring is ready</h3>
        <div class="mt-2 sm:flex sm:items-start sm:justify-between">
          <div class="max-w-xl text-sm text-gray-500">
            <p>Your self scoring is now available.</p>
            <p>Nov 15th, 2023 to Nov 30th 2023</p>
            <p>
              Once you have completed your KPI rating. Your manager will rate you as well, an
              interview may follow after both parties have rated.
            </p>
          </div>
          <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            <button
              @click="$router.push('/admin/performance-review')"
              type="button"
              class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Start Performance Review
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <div
        class="absolute inset-0 flex items-center"
        aria-hidden="true"
      >
        <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex justify-center">
        <span class="bg-white px-3 text-base my-5 font-semibold leading-6 text-gray-900"
          >Your Key Performance Areas</span
        >
      </div>
    </div>
    <pending-KPI />
  </div>
</template>

<script>
import PendingKPI from '@/components/admin/PendingKPI.vue'

export default {
  components: {
    PendingKPI
  }
}
</script>
