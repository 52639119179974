<template>
  <ul
    percentage="list"
    class="divide-y divide-gray-100"
  >
    <li
      v-for="person in people"
      :key="person.email"
      class="flex justify-between gap-x-6 py-5"
    >
      <div class="flex min-w-0 gap-x-4">
        <!-- <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="person.imageUrl" alt="" /> -->
        <div class="min-w-0 flex-auto">
          <p class="text-sm font-semibold leading-6 text-gray-900">{{ person.name }}</p>
          <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{ person.email }}</p>
        </div>
      </div>
      <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
        <p class="text-sm leading-6 text-gray-900">{{ person.percentage }}</p>

        <!-- <div v-if="person.lastSeen" class="mt-1 flex items-center gap-x-1.5">
           <div  class="flex-none rounded-full bg-red-500/20 p-1">
            <div class="h-1.5 w-1.5 rounded-full bg-red-500" />
          </div>
          <p class="text-xs leading-5 text-gray-500">Manager</p>
        </div>
        <div v-else class="mt-1 flex items-center gap-x-1.5">
          <div class="flex-none rounded-full bg-emerald-500/20 p-1">
            <div class="h-1.5 w-1.5 rounded-full bg-emerald-500" />
          </div>
          <p class="text-xs leading-5 text-gray-500">Manager</p>
        </div> -->
      </div>
      <!-- <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
        <p class="text-sm leading-6 text-gray-900">{{ person.percentage }}</p>

        <div v-if="person.lastSeen" class="mt-1 flex items-center gap-x-1.5">
           <div  class="flex-none rounded-full bg-red-500/20 p-1">
            <div class="h-1.5 w-1.5 rounded-full bg-red-500" />
          </div>
          <p class="text-xs leading-5 text-gray-500">Manager</p>
        </div>
        <div v-else class="mt-1 flex items-center gap-x-1.5">
          <div class="flex-none rounded-full bg-emerald-500/20 p-1">
            <div class="h-1.5 w-1.5 rounded-full bg-emerald-500" />
          </div>
          <p class="text-xs leading-5 text-gray-500">Manager</p>
        </div>
      </div> -->
    </li>
  </ul>
</template>

<script setup>
const people = [
  {
    name: 'Sales Target',
    email: 'You are required to make 50 sales per month, with a minimum of 5 sales per week.',
    percentage: '30%',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z'
  },
  {
    name: 'Time Management',
    email: 'Time spent on each task should be recorded and submitted at the end of each day.',
    percentage: '10%',
    imageUrl:
      'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z'
  },
  {
    name: 'Team Coaching',
    email: 'You are required to coach your team members on a weekly basis.',
    percentage: '20%',
    imageUrl:
      'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    lastSeen: null
  },
  {
    name: 'Intiative',
    email: 'You are required to have initiative in your work.',
    percentage: '40%',
    imageUrl:
      'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z'
  }
]
</script>
