import { createRouter, createWebHistory } from 'vue-router'

import AdminLayout from '../layouts/AdminLayout.vue'

// Admin
import AdminHomeView from '../views/Admin/AdminHomeView.vue'
import AdminSettingsView from '../views/Admin/AdminSettingsView.vue'
import CompanyNewsView from '../views/Admin/News/CompanyNewsView.vue'
import CompanyNewsPostView from '../views/Admin/News/CompanyNewsPostView.vue'
import DocumentsView from '../views/Admin/Documents/DocumentsView.vue'
import DocumentView from '../views/Admin/Documents/DocumentView.vue'
import TeamMembersView from '../views/Admin/Teams/TeamMembersView.vue'
import TraningLinksView from '../views/Admin/Training/TrainingLinksView.vue'
import ContactHumanResourcesView from '../views/Admin/HumanResources/ContactHumanResourcesView.vue'
import UpcomingKPIView from '../views/Admin/KPI/UpcomingKPIView.vue'
import KPIReportView from '../views/Admin/KPI/KPIReportView.vue'
import PerformanceReviewView from '../views/Admin/KPI/PerformanceReviewView.vue'

const routes = [
  // Auth
  {
    path: '/auth',
    name: 'auth-layout',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '/login',
        name: 'login-view',
        component: () => import('@/views/Auth/LoginView.vue')
      },
      {
        path: '/register',
        name: 'register-view',
        component: () => import('@/views/Auth/RegisterView.vue')
      },
      {
        path: '/auth/password-reset-request',
        name: 'password-reset-request-view',
        component: () =>
          import('@/views/Auth/PasswordReset/PasswordResetRequest.vue')
      },
      {
        path: '/auth/password-reset-confirmation',
        name: 'password-reset-confirmation-view',
        component: () =>
          import('@/views/Auth/PasswordReset/PasswordResetConfirmation.vue')
      },
      {
        path: '/auth/view-selection',
        name: 'view-selection',
        component: () => import('@/views/Auth/ViewSelection.vue'),
        meta: {
          auth: true
        }
      }
    ]
  },
  // Admin
  {
    path: '/admin',
    name: 'admin-layout',
    component: AdminLayout,
    meta: {
      auth: false
    },
    children: [
      {
        path: '/admin/home',
        name: 'admin-home-view',
        component: AdminHomeView
      },
      {
        path: '/admin/settings',
        name: 'admin-settings-view',
        component: AdminSettingsView
      },
      {
        path: '/admin/company-news',
        name: 'admin-company-news',
        component: CompanyNewsView
      },
      {
        path: '/admin/company-news-post/:id',
        name: 'admin-company-news-post',
        component: CompanyNewsPostView
      },
      {
        path: '/admin/documents',
        name: 'admin-documents-view',
        component: DocumentsView
      },
      {
        path: '/admin/document/:id',
        name: 'admin-document-view',
        component: DocumentView
      },
      {
        path: '/admin/team-members',
        name: 'admin-team-members-view',
        component: TeamMembersView
      },
      {
        path: '/admin/training-links',
        name: 'training-links',
        component: TraningLinksView
      },
      {
        path: '/admin/human-resources',
        name: 'admin-human-resources',
        component: ContactHumanResourcesView
      },
      {
        path: '/admin/upcoming-kpi',
        name: 'admin-upcoming-kpi-view',
        component: UpcomingKPIView
      },
      {
        path: '/admin/kpi-reports',
        name: 'admin-kpi-report',
        component: KPIReportView
      },
      {
        path: '/admin/performance-review',
        name: 'admin-performance-review',
        component: PerformanceReviewView
      }
    ]
  },
  // Website
  {
    path: '/',
    name: 'website-layout',
    component: () => import('@/layouts/WebsiteLayout.vue'),
    children: [
      {
        path: '/',
        name: 'home-view',
        component: () => import('@/views/Website/HomeView.vue')
      },
      {
        path: '/about',
        name: 'about-view',
        component: () => import('@/views/Website/AboutView.vue')
      },
      {
        path: '/contact',
        name: 'contact-view',
        component: () => import('@/views/Website/ContactView.vue')
      },
      {
        path: '/features',
        name: 'features-view',
        component: () => import('@/views/Website/FeaturesView.vue')
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy-view',
        component: () => import('@/views/Website/PrivacyPolicyView.vue')
      },
      {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions-view',
        component: () => import('@/views/Website/TermsAndConditionsView.vue')
      },
      {
        path: '/pricing',
        name: 'pricing-view',
        component: () => import('@/views/Website/Checkout/PricingView.vue')
      }
    ]
  },
  // Common
  {
    path: '/not-authorised',
    name: 'not-authorised',
    component: () => import('@/views/NotAuthorised.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: () => import('@/views/PageNotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes
})

export default (app) => {
  app.router = router
  app.use(router)
}
