<template>
  <div class="overflow-hidden rounded-lg bg-white shadow">
    <h2
      class="sr-only"
      id="profile-overview-title"
    >
      Profile Overview
    </h2>
    <div class="bg-white p-6">
      <div class="sm:flex sm:items-center sm:justify-between">
        <div class="sm:flex sm:space-x-5">
          <div class="flex-shrink-0">
            <img
              class="mx-auto h-20 w-20 rounded-full"
              :src="user.imageUrl"
              alt=""
            />
          </div>
          <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
            <p class="text-sm font-medium text-gray-600">Welcome back,</p>
            <p class="text-xl font-bold text-gray-900 sm:text-2xl">{{ user.name }}</p>
            <p class="text-sm font-medium text-gray-600">{{ user.role }}</p>
          </div>
        </div>
        <div class="mt-5 flex justify-center sm:mt-0">
          <a
            href="/admin/settings"
            class="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >View profile</a
          >
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0"
    >
      <div
        v-for="stat in stats"
        :key="stat.label"
        class="px-6 py-5 text-center text-sm font-medium"
      >
        <span class="text-gray-900">{{ stat.value }}</span>
        {{ ' ' }}
        <span class="text-gray-600">{{ stat.label }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
const user = {
  name: 'Dudu Mfene',
  role: 'Product Designer',
  imageUrl:
    'https://images.unsplash.com/photo-1524159730786-4e74a1b78d7d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
}
const stats = [
  { label: '15th Dec 2023', value: 'Upcoming KPI:' },
  { label: '2023 Jan 15', value: 'Start Date:' },
  { label: 'Permanent', value: 'Contract End:' }
]
</script>
